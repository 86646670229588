import { Grid } from "@material-ui/core";
import {
  CustomCheckbox,
  DropDown,
  FormSection,
  GriddedTextField,
} from "components/UiComponents";
import GriddedDropDownField from "components/UiComponents/GriddedDropdownField";
import { useInputContext } from "contexts/InputContext";
import React from "react";

const DeviceConnectionConfigurationForm: React.FC<any> = ({
  connectionRequestPayload,
  onFieldChange,
}) => {
  return (
    <>
      <GriddedTextField
        label="Device Title"
        name="deviceTitle"
        value={connectionRequestPayload.title || ""}
        onChange={(value: string) => onFieldChange("title", value)}
      />
      <GriddedTextField
        label="Device Description *"
        name="deviceDescription"
        value={connectionRequestPayload.description || ""}
        onChange={(value: string) => onFieldChange("description", value)}
      />
      <Grid item xs={6}>
        <DropDown
          name="devClass"
          options={["A", "B", "C", "D"].map((c) => ({ value: c, label: c }))}
          value={connectionRequestPayload.devclass || ""}
          label="Class *"
          onChange={(value: string) => onFieldChange("devclass", value)}
        />
      </Grid>
      <GriddedTextField
        label="App Key *"
        name="appKey"
        value={connectionRequestPayload.appkey || ""}
        onChange={(value: string) => onFieldChange("appkey", value)}
      />
      <GriddedTextField
        readOnly
        name="devEui"
        label="Device EUI *"
        value={connectionRequestPayload.deveui || ""}
        onChange={(value: string) => onFieldChange("deveui", value)}
      />
      <GriddedTextField
        label="App EUI *"
        name="appEui"
        value={connectionRequestPayload.appeui || ""}
        onChange={(value: string) => onFieldChange("appeui", value)}
      />
    </>
  );
};

const ConnectionConfiguration: React.FC<any> = ({
  deviceId,
  connectionConfig,
  onFieldChange,
}: any) => {
  const inputs = useInputContext();
  const [showDropdown, setShowDropdown] = React.useState(false);

  return (
    <Grid container>
      <CustomCheckbox
        checked={showDropdown}
        onChange={() => {
          setShowDropdown(!showDropdown);
          onFieldChange("has_connection_config", !showDropdown);
        }}
        label="Add a connection configuration"
        style={{ margin: "1em 0 -1em 0.5em" }}
        labelStyle={{ fontSize: "18px" }}
      />
      {showDropdown && (
        <FormSection>
          <GriddedDropDownField
            label="Connection Configuration *"
            options={(inputs.conn_configs || []).map(
              ({ config_name: label, conn_config_id: value }: any) => ({
                label,
                value,
              })
            )}
            value={connectionConfig.conn_config_id}
            onChange={(value: string) => {
              const selectedConnectionConfig = inputs.conn_configs.find(
                (config: any) => config.conn_config_id === value
              );
              onFieldChange("conn_config", {
                ...selectedConnectionConfig,
                conn_request_payload: {
                  title: deviceId,
                  description: deviceId,
                  deveui: deviceId,
                  devclass: "A",
                },
              });
            }}
          />
          {connectionConfig.conn_config_id && (
            <DeviceConnectionConfigurationForm
              connectionRequestPayload={connectionConfig.conn_request_payload}
              onFieldChange={(field: string, value: string) => {
                const updatedConnectionRequestPayload = {
                  ...connectionConfig.conn_request_payload,
                  [field]: value,
                };
                onFieldChange("conn_config", {
                  ...connectionConfig,
                  conn_request_payload: updatedConnectionRequestPayload,
                });
              }}
            />
          )}
        </FormSection>
      )}
    </Grid>
  );
};

export default ConnectionConfiguration;
