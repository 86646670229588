import React from "react";
import { DropDown, GriddedTextField } from "../../../UiComponents";
import { Grid } from "@material-ui/core";
import { useApplicationContext } from "../../../../contexts/ApplicationContext";
import { useInputContext } from "../../../../contexts/InputContext";
import { CONFIG_PAGE } from "../types";

//TODO: Create GriddedDropdown Component
const EDGE_TYPES = ["NON_EDGE"];
const DEVICE_TYPES = ["THING", "GATEWAY", "STANDALONE"];
const AUTH_TYPES = ["SAS", "X509"];

const deriveOptions = (options: string[]) =>
  options.map((opt) => ({ label: opt, value: opt }));

interface IBasicFieldsProps {
  basicFields: any;
  onFieldChange: (fName: string, value: string) => void;
  page: CONFIG_PAGE;
}

const BasicConfigFields: React.FC<IBasicFieldsProps> = ({
  basicFields,
  onFieldChange,
  page,
}) => {
  const { applications } = useApplicationContext();
  const { auth_type = [] } = useInputContext();
  const isEdit = page === CONFIG_PAGE.EDIT_CONFIG;
  return (
    <>
      <Grid item xs={6}>
        <DropDown
          label="Applications *"
          name="selectApplication"
          options={(applications || []).map(
            ({ application_id: value, application_name: label }) => ({
              value,
              label,
            })
          )}
          value={basicFields.application_id}
          onChange={(value: string) => onFieldChange("application_id", value)}
          disabled={isEdit}
        />
      </Grid>

      <GriddedTextField
        name="configName"
        label="Configuration Name *"
        value={basicFields.config_name}
        onChange={(value: string) => onFieldChange("config_name", value)}
        readOnly={isEdit}
      />
      <Grid item xs={6}>
        <DropDown
          label="Device Type *"
          name="deviceType"
          options={deriveOptions(DEVICE_TYPES)}
          value={basicFields.device_type}
          onChange={(value: string) => onFieldChange("device_type", value)}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <DropDown
          label="Edge Type *"
          name="edgeType"
          options={deriveOptions(EDGE_TYPES)}
          value={basicFields.edge_type}
          onChange={(value: string) => onFieldChange("edge_type", value)}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <DropDown
          label="Auth Type *"
          name="authType"
          options={deriveOptions(isEdit ? AUTH_TYPES : auth_type)} //in Add, auth type is specific to application selected
          value={basicFields.auth_type}
          onChange={(value: string) => onFieldChange("auth_type", value)}
          disabled={isEdit}
        />
      </Grid>
      <GriddedTextField
        name="configDesc"
        label="Configuration Description *"
        value={basicFields.config_desc}
        onChange={(value: string) => onFieldChange("config_desc", value)}
        fullWidth
      />
    </>
  );
};

export default BasicConfigFields;
