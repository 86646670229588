/*
device_id_old
device_id_new
device_name
properties
serial_num
installation_status
tags
fields
conn_request_payload
*/

enum CSV_COLUMNS {
  device_id_old,
  device_id_new,
  device_name,
  properties,
  serial_num,
  installation_status,
  tags,
  fields,
  conn_request_payload,
}
const BULK_REPLACE_RECORD_LIMIT = 500;
export { CSV_COLUMNS, BULK_REPLACE_RECORD_LIMIT };
