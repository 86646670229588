import { CSV_COLUMNS } from "../constants";
import getKeysFromEnum from "./getKeysFromEnums";

const jsonExtractor = (stringifiedJson: string) => {
  const sanitizedStringifiedJson = (stringifiedJson || "").trim();
  if (!sanitizedStringifiedJson) {
    return {};
  }
  return JSON.parse(sanitizedStringifiedJson);
};

type ExtractorFunction = (record: any) => any;

const extractors: { [key in CSV_COLUMNS]?: ExtractorFunction } = {
  [CSV_COLUMNS.tags]: (record: any) => {
    return jsonExtractor(record[CSV_COLUMNS.tags]);
  },
  [CSV_COLUMNS.properties]: (record: any) => {
    return jsonExtractor(record[CSV_COLUMNS.properties]);
  },
  [CSV_COLUMNS.fields]: (record: any) => {
    return jsonExtractor(record[CSV_COLUMNS.fields]);
  },
  [CSV_COLUMNS.conn_request_payload]: (record: any) => {
    return jsonExtractor(record[CSV_COLUMNS.conn_request_payload]);
  },
};

const getDefaultExtractor = (columnName: string) => {
  return (record: any) => record[CSV_COLUMNS[columnName]] || "";
};

const extractColumnData = (columnName: string, record: any) => {
  const extractor =
    extractors[CSV_COLUMNS[columnName]] || getDefaultExtractor(columnName);
  return extractor(record);
};

const extractData = (fileData: any) => {
  const [_, ...records] = fileData; //first row is header
  const deviceRecords = records.map((record: any) => {
    const columns = getKeysFromEnum(CSV_COLUMNS);
    let deviceRecord = {};
    columns.forEach((columnName) => {
      deviceRecord = {
        ...deviceRecord,
        [columnName]: extractColumnData(columnName, record),
      };
    });
    return deviceRecord;
  });
  return deviceRecords;
};

export default extractData;
