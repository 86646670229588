import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "center",
      margin: "1em .5",
      color: "white",
      flexDirection: "column",
    },
    header: {
      background: "#2B2B36",
      width: "98%",
      color: "white",
      height: "4em",
      padding: "1em",
    },
    tagWrapper: {
      background: "#2B2B36",
      margin: "1em",
      padding: "30px 0 30px 0",
      borderRadius: "4px",
      width: "98%",
    },
    buttonWrapper: {
      display: "flex",
      width: "98%",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "2em",
      borderTop: "1px solid #3b3b3b",
      padding: "1em .5em",
    },
    sectionHeader: {
      width: "100%",
      paddingLeft: "1em",
      fontSize: "18px",
      marginTop: "1em",
    },
    subtitle: {
      color: "white",
      fontSize: "12px",
      lineHeight: "14px",
      marginTop: "23px",
    },
  })
);

export default useStyles;
