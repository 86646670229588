import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      display: "flex",
      width: "98%",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "2em",
      borderTop: "1px solid #3b3b3b",
      padding: "1em .5em",
    },
  })
);

export default useStyles;
