import { CSV_COLUMNS, BULK_REPLACE_RECORD_LIMIT } from "../constants";
import getKeysFromEnum from "./getKeysFromEnums";
import { installationStatusDropDownOptions } from "../../../../../../utils/constant";
import { isEmpty } from "utils/helper";

//TODO: Pull from consts
const letterNumberID = /^[0-9a-zA-Z_-]*$/;
const letterNumber = /^[0-9a-zA-Z_-]+$/;
const LETTER_CHARACTER_LIMIT = 50;
const tthex = /^[0-9a-fA-F]{32}$/;
const sthex = /^[0-9a-fA-F]{16}$/;

const checkHeaders = (headerRow: any[] = []) => {
  const columns = getKeysFromEnum(CSV_COLUMNS);
  if (
    columns.some((key) => !(headerRow[CSV_COLUMNS[key]] || "").includes(key))
  ) {
    throw new Error(
      "File headers should be in correct order. Please download sample CSV for reference."
    );
  }
};

const checkForDuplicateRecords = (records: any) => {
  const [_, ...deviceRecords] = records;
  const newDeviceIds = deviceRecords.map(
    (record: any) => record[CSV_COLUMNS.device_id_new]
  );

  if (new Set(newDeviceIds).size !== newDeviceIds.length) {
    throw new Error("Multiple records with same 'new device id' found in CSV");
  }

  const oldDeviceIds = deviceRecords.map(
    (record: any) => record[CSV_COLUMNS.device_id_old]
  );
  if (new Set(oldDeviceIds).size !== oldDeviceIds.length) {
    throw new Error("Multiple records with same 'old device id' found in CSV");
  }
};

const validateJSON = (
  stringifiedJSON: string = "",
  index: number,
  fieldName: "tag" | "property" | "connection_reqeust_payload"
) => {
  const trimmedStringifiedJSON = stringifiedJSON.trim();
  if (!trimmedStringifiedJSON) {
    return "";
  }
  try {
    return JSON.parse(trimmedStringifiedJSON);
  } catch (error) {
    throw new Error(
      `Invalid ${fieldName} at row ${index}. Value is not a JSON.`
    );
  }
};

const validateConnectionRequestPayload = (
  connectionRequestPayload: any,
  index: number
) => {
  if (isEmpty(connectionRequestPayload.title)) {
    throw new Error(`Please enter device title for LORIOT at row ${index}`);
  }

  if (isEmpty(connectionRequestPayload.description)) {
    throw new Error(
      `Please enter device description for LORIOT at row ${index}`
    );
  }

  if (isEmpty(connectionRequestPayload.devclass)) {
    throw new Error(`Please select device class at row ${index}`);
  }

  if (
    isEmpty(connectionRequestPayload.appkey) ||
    !tthex.test(connectionRequestPayload.appkey)
  ) {
    throw new Error(
      `Please enter valid App Key (32 character HEX) at row ${index}`
    );
  }

  if (
    isEmpty(connectionRequestPayload.appeui) ||
    !sthex.test(connectionRequestPayload.appeui)
  ) {
    throw new Error(
      `Please enter valid App EUI (16 character HEX) at row ${index}`
    );
  }

  if (
    isEmpty(connectionRequestPayload.deveui) ||
    !sthex.test(connectionRequestPayload.deveui)
  ) {
    throw new Error(
      `Please enter valid Device EUI (16 character HEX) at row ${index}`
    );
  }

  if (
    (connectionRequestPayload.deveui || "").toUpperCase() !==
    (connectionRequestPayload.deveui || "")
  ) {
    throw new Error(`Please enter Device EUI in upper case at row ${index}`);
  }
};

const validators = {
  [CSV_COLUMNS.device_id_new]: (record: any, index: number) => {
    const deviceId = record[CSV_COLUMNS.device_id_new];
    if (
      isEmpty(deviceId) ||
      !letterNumberID.test(deviceId) ||
      deviceId.length > 64
    ) {
      throw new Error(
        `Invalid Device ID (new) at row ${index}. (only 0-9,A-Z,a-z,_,- allowed upto 64 characters)`
      );
    }
  },
  [CSV_COLUMNS.device_id_old]: (record: any, index: number) => {
    const deviceId = record[CSV_COLUMNS.device_id_old];
    if (
      isEmpty(deviceId) ||
      !letterNumberID.test(deviceId) ||
      deviceId.length > 64
    ) {
      throw new Error(
        `Invalid Device ID (old) at row ${index}. (only 0-9,A-Z,a-z,_,- allowed upto 64 characters)`
      );
    }
  },
  [CSV_COLUMNS.device_name]: (record: any, index: number) => {
    const deviceName = record[CSV_COLUMNS.device_name];
    if (
      isEmpty(deviceName) ||
      !letterNumber.test(deviceName) ||
      deviceName.length > LETTER_CHARACTER_LIMIT
    ) {
      throw new Error(
        `Invalid Device Name at row ${index}. (only 0-9,A-Z,a-z,_,- allowed upto ${LETTER_CHARACTER_LIMIT} characters)`
      );
    }
  },
  [CSV_COLUMNS.serial_num]: (record: any, index: number) => {
    const serialNumber = record[CSV_COLUMNS.serial_num];
    if (
      isEmpty(serialNumber) ||
      !letterNumber.test(serialNumber) ||
      serialNumber.length > LETTER_CHARACTER_LIMIT
    ) {
      throw new Error(
        `Invalid Serial Number at row ${index}. (only 0-9,A-Z,a-z,_,- allowed upto ${LETTER_CHARACTER_LIMIT} characters)`
      );
    }
  },
  [CSV_COLUMNS.tags]: (record: any, index: number) => {
    validateJSON(record[CSV_COLUMNS.tags], index, "tag"); //this will throw error if JSON is wrong
    return "";
  },
  [CSV_COLUMNS.properties]: (record: any, index: number) => {
    validateJSON(record[CSV_COLUMNS.properties], index, "property");
    return "";
  },
  [CSV_COLUMNS.conn_request_payload]: (record: any, index: number) => {
    const connectionRequestPayload = record[CSV_COLUMNS.conn_request_payload];
    if ((connectionRequestPayload || "").trim() === "") {
      return;
    }
    validateJSON(connectionRequestPayload, index, "connection_reqeust_payload");
    const parsedConnectionRequestPayload = JSON.parse(connectionRequestPayload);
    validateConnectionRequestPayload(parsedConnectionRequestPayload, index);
    const newDeviceId = record[CSV_COLUMNS.device_id_new];
    
    if (parsedConnectionRequestPayload.deveui !== newDeviceId) {
      throw new Error(`Device EUI should be same as Device ID at row ${index}`);
    }

    return "";
  },
  [CSV_COLUMNS.installation_status]: (record: any, index: number) => {
    const validInstallationStatuses = installationStatusDropDownOptions.map(
      ({ label }: any) => label.toLowerCase()
    );
    const uploadedInstallationStatus = (
      record[CSV_COLUMNS.installation_status] || ""
    )
      .trim()
      .toLowerCase();

    if (!uploadedInstallationStatus) {
      return "";
    }

    if (!validInstallationStatuses.includes(uploadedInstallationStatus)) {
      throw new Error(`Invalid Installation Status at row ${index}`);
    }
  },
};

const getValidator = (columnName: string) => {
  const columnId = CSV_COLUMNS[columnName];
  return validators[columnId] || (() => true);
};

const hasUtleastOneColumnToUpdate = (record: any[]) => {
  //skip first column as it will be always device id
  return !record.slice(1).every((v) => !(v + "").trim());
};

const validateColumns = (sanitizedData: any) => {
  const columns = getKeysFromEnum(CSV_COLUMNS);
  //will start from index 1 to skip header
  for (let i = 1; i < sanitizedData.length; i++) {
    const record = sanitizedData[i];
    if (!hasUtleastOneColumnToUpdate(record)) {
      throw new Error(
        `There is nothing to update at row ${i + 1}. Please remove the row.`
      );
    }
    columns.forEach((column: any) => {
      const validator = getValidator(column);
      validator(record, i + 1);
    });
  }
};

const validateData = (sanitizedData: any) => {
  const totalReacords = sanitizedData.length - 1;
  if (totalReacords > BULK_REPLACE_RECORD_LIMIT) {
    throw new Error(
      `Maximum Record Count (${BULK_REPLACE_RECORD_LIMIT}) Exceeded`
    );
  }
  checkHeaders(sanitizedData[0]);
  checkForDuplicateRecords(sanitizedData);
  validateColumns(sanitizedData);
};

export default validateData;
