import React from "react";
import { Grid } from "@material-ui/core";

import { DropDown, IDropDownProps } from "./DropDown";

interface IGriddedDropDownProps extends IDropDownProps {
  fullWidth?: boolean;
  error?: string;
}

const GriddedDropDownField: React.FC<IGriddedDropDownProps> = ({
  fullWidth,
  error,
  ...props
}) => {
  return (
    <Grid item xs={fullWidth ? 12 : 6}>
      <DropDown {...props} />
      {error && (
        <p style={{ color: "#D72B3F", fontSize: 12, margin: "-0.5em 0 0 2em" }}>
          {error}
        </p>
      )}
    </Grid>
  );
};

export default GriddedDropDownField;
