import React, { useEffect } from "react";
import { DropDown, LoadingIndicator } from "../../../../UiComponents";

import useApi from "../../../../../hooks/useApi";
import { useApplicationContext } from "../../../../../contexts/ApplicationContext";

interface IInstallationStatusDropdownProps {
  onChange: (selectedValue: string) => void;
  currentInstallStatusLabel: string;
  value: string;
}

const getInstallationStatusOptions = (installOptios: Array<any>) => {
  return installOptios.map(
    ({
      device_install_status_id: value,
      device_installation_status: label,
    }) => ({ value, label })
  );
};

const InstallationStatusDropdown: React.FC<
  IInstallationStatusDropdownProps
> = ({ onChange, value }) => {
  const { selectedApplication } = useApplicationContext();
  const { data, status } = useApi(
    `/applications/${selectedApplication.application_id}/device-installation/inputs`,
    {
      method: "GET",
      responseDataFormatter: (responseData: any) =>
        responseData
          ? responseData.device_install_input[0]?.device_install_status || []
          : [],
    }
  );

  useEffect(() => {
    if (data && data.length === 0) {
      onChange(""); //this will make the selection invalid and the parent can disable saving
    }
  }, [data]);

  if (!data || status.pending) {
    return <LoadingIndicator />;
  }

  if (data.length === 0) {
    return <div>You are not authorized to perform the action</div>;
  }

  const installationStatusOptions = getInstallationStatusOptions(data);

  /* KNOWN_ISSUE: As installationStatuses are fetched from RBAC, current value may be missing from the options.
  Material UI will trigger a warning in this case.*/
  return (
    <DropDown
      label="Change to"
      name="installationStatus"
      options={installationStatusOptions}
      value={value}
      onChange={(selectedValue) => onChange(selectedValue)}
    />
  );
};

export default InstallationStatusDropdown;
