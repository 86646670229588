import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import { DEFAULT_INSTALL_STATUS_VALUE } from "utils/constant";
import { DeviceTagManager } from "components/UiComponents";
import { InputContextProvider } from "contexts/InputContext";
import {
  BasicDeviceFields,
  ConnectionConfiguration,
  Submit,
} from "components/Devices/DeviceManagement/AddDevice/AddThing/formParts";

import { DeviceProperties } from "components/Devices/DeviceManagement/sharedFormParts";
import useStyles from "./AddThing.styles";
import { callApi } from "hooks/useApi/useApi";

const DEFAULT_FORM_VALUES = {
  device_install_status_id: DEFAULT_INSTALL_STATUS_VALUE,
};

const getIotHubExpiryDays = (iotHubs: any[], selectedIotHubName: string) => {
  return (
    (iotHubs || []).find(
      ({ iot_hub_name }: any) => iot_hub_name === selectedIotHubName
    )?.expiry_days || 1 //returning a positive number if not found
  );
};

/*NOTE: IoTHub list from inputs endpoint is not used here. Instead, it is
 fetched from the device-config endpoint when user selects a config.*/
const AddDevice: React.FC<any> = ({ returnHome }) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState<any>(DEFAULT_FORM_VALUES);
  const [iotHubLists, setIotHubLists] = useState<any>([]);

  const fetchIotHubs = async (configId: number) => {
    const apiResponse = await callApi(
      `/applications/${formValues.application_id}/device-config/${configId}`,
      {
        method: "GET",
      }
    );

    const iotHubLists = apiResponse?.az_iot_hubs || [];
    setIotHubLists(iotHubLists);
  };

  const isSelectedIotHubExpired =
    getIotHubExpiryDays(iotHubLists, formValues.cloud_gateway) <= 0;

  //reset formValues
  useEffect(() => {
    setFormValues({
      ...DEFAULT_FORM_VALUES,
      application_id: formValues.application_id,
    });
  }, [formValues?.application_id]);

  return (
    <InputContextProvider
      applicationId={+formValues.application_id}
      lookup={{
        selectedConfigId: formValues.device_config?.device_config_id,
        iotHubLists,
      }}
    >
      <div className={classes.wrapper}>
        <Grid container>
          <BasicDeviceFields
            deviceDetails={formValues}
            flags={{ isSelectedIotHubExpired }}
            onFieldChange={(field: string, value: any) => {
              if (field === "device_config") {
                setFormValues({
                  ...formValues,
                  [field]: value,
                  cloud_gateway: "",
                });

                fetchIotHubs(value?.device_config_id);
              } else if (
                field === "device_id" &&
                formValues?.has_connection_config
              ) {
                setFormValues({
                  ...formValues,
                  device_id: value,
                  conn_config: {
                    ...formValues.conn_config,
                    conn_request_payload: {
                      ...formValues?.conn_config?.conn_request_payload,
                      title: value,
                      description: value,
                      deveui: value,
                    },
                  },
                });
              } else {
                setFormValues({ ...formValues, [field]: value });
              }
            }}
          />

          {formValues.device_config && (
            <DeviceTagManager
              tags={formValues.device_config.tags}
              onTagRenderingError={() => console.log("tag rendering error")}
              onTagUpdate={(updatedTags: any) => {
                setFormValues({
                  ...formValues,
                  device_config: {
                    ...formValues.device_config,
                    tags: updatedTags,
                  },
                });
              }}
            />
          )}
        </Grid>
        {formValues.device_config?.properties && (
          <DeviceProperties
            properties={formValues.device_config.properties}
            onPropertyChange={(propertyName: string, value: any) => {
              setFormValues({
                ...formValues,
                device_config: {
                  ...formValues.device_config,
                  properties: {
                    ...formValues.device_config.properties,
                    [propertyName]: value,
                  },
                },
              });
            }}
          />
        )}
        {formValues.device_config && (
          <ConnectionConfiguration
            deviceId={formValues.device_id}
            connectionConfig={formValues.conn_config || {}}
            onFieldChange={(field: string, value: string) => {
              setFormValues({
                ...formValues,
                [field]: value,
              });
            }}
          />
        )}

        <Submit
          application={formValues.application_id}
          onDone={returnHome}
          formValues={formValues}
          disabled={isSelectedIotHubExpired}
        />
      </div>
    </InputContextProvider>
  );
};

export default AddDevice;
