import React from "react";
import { Grid, Typography } from "@material-ui/core";

import { GriddedTextField, DropDown } from "../../../../UiComponents";
import { useInputContext } from "../../../../../contexts/InputContext";

const Tuple: React.FC<any> = ({ label, value }) => {
  return (
    <Grid item style={{ width: "13em" }}>
      <Typography style={{ fontWeight: "bolder", marginBottom: "5px" }}>
        {label}
      </Typography>
      <Typography>{value}</Typography>
    </Grid>
  );
};

const ConfigInformation: React.FC<any> = ({ configDetails }) => {
  return (
    <Grid
      container
      xs={12}
      style={{ margin: "1em 1.5em", background: "#2b2b36", padding: "1em" }}
    >
      <Tuple label="Device Type" value={configDetails.device_type} />
      <Tuple label="Edge Type" value={configDetails.edge_type} />
      <Tuple label="Authentication Type" value={configDetails.auth_type} />
    </Grid>
  );
};

const BasicDeviceFields: React.FC<any> = ({ deviceDetails, onFieldChange }) => {
  const inputs = useInputContext();

  const applicationLevelOptions = inputs.application_level.map(
    ({ level_name: label, level_id: value }: any) => ({ label, value })
  );

  const installationStatusOptions = inputs.install_status
    ? inputs.install_status.map(
        ({
          device_install_status_id: value,
          device_installation_status: label,
        }: any) => ({ value, label })
      )
    : null;

  return (
    <>
      <GriddedTextField
        readOnly
        label="Application"
        value={deviceDetails.application_name}
      />

      <GriddedTextField
        readOnly
        name="device_configuration"
        label="Device Configuration"
        value={deviceDetails.device_config.config_name}
      />
      <ConfigInformation configDetails={deviceDetails.device_config} />
      <GriddedTextField
        readOnly
        label="Device Id *"
        value={deviceDetails.device_id}
      />

      <GriddedTextField
        name="device_name"
        label="Device Name *"
        value={deviceDetails.device_name}
        onChange={(value: string) => onFieldChange("device_name", value)}
      />
      <GriddedTextField
        name="serial_num"
        label="Serial Number *"
        value={deviceDetails.serial_num}
        onChange={(value: string) => onFieldChange("serial_num", value)}
      />
      <GriddedTextField
        readOnly
        label="IoT Hub *"
        name="iot_name"
        value={deviceDetails.cloud_gateway}
      />
      <Grid item xs={6}>
        <DropDown
          label="Application Level *"
          name="selectApplicationLevel"
          options={applicationLevelOptions}
          value={deviceDetails.application_level_id}
          onChange={(value: string) =>
            onFieldChange("application_level_id", value)
          }
        />
      </Grid>
      {installationStatusOptions && (
        <Grid item xs={6}>
          <DropDown
            label="Installation Status"
            name="installationStatus"
            options={installationStatusOptions}
            value={deviceDetails.device_install_status_id}
            onChange={(value: string) =>
              onFieldChange("device_install_status_id", value)
            }
          />
        </Grid>
      )}
    </>
  );
};

export default BasicDeviceFields;
