import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

import { useSnackbar } from "notistack";
import { CustomButton, DataTable } from "components/UiComponents";
import { exportToExcel } from "utils/helper";

enum BULK_OPERATION_STATUS {
  NO_RECORD = "NO-RECORD",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN-PROGRESS",
  FAILED = "FAILED",
}

const Record = ({ label, value }: any) => {
  return (
    <Grid container>
      <Grid container item xs={3}>
        <Typography style={{ fontWeight: "bold" }}>{label}</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>: {value}</Typography>
      </Grid>
    </Grid>
  );
};
const MAX_DISPLAYED_RECORD = 2;

const DownloadableDeviceList = ({
  deviceList,
  label,
  csvSheetName,
  columnSchema,
}: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const downloadableDeviceList = (deviceList || []).map((device: any) => {
    const deviceItem: any = {};
    columnSchema.forEach(({ label, id }: any) => {
      deviceItem[label] = device[id];
    });
    return deviceItem;
  });
  return (
    <Grid
      style={{
        padding: "0.5em",
        border: "1px solid #3c3c3c",
        marginTop: "1em",
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        // style={{ margin: "5px 0 0 0" }}
      >
        <Typography style={{ fontWeight: "bold" }}>{label}</Typography>
        <CustomButton
          variant="link-blue"
          onClick={() => {
            exportToExcel(downloadableDeviceList, csvSheetName, 2);
            enqueueSnackbar("Export initiated", { variant: "success" });
          }}
        >
          Export To CSV
        </CustomButton>
      </Grid>

      <DataTable
        columns={columnSchema}
        values={deviceList.slice(0, MAX_DISPLAYED_RECORD)}
        noWrapper
        refreshGrid={() => true}
      />
      {deviceList.length > MAX_DISPLAYED_RECORD && (
        <Typography
          style={{
            margin: "5px 5px 0px 0px",
            color: "#8a8a8a",
            textAlign: "right",
          }}
        >
          ... and {deviceList.length - MAX_DISPLAYED_RECORD} more
        </Typography>
      )}
    </Grid>
  );
};

const FailedDeviceList = ({ deviceList }: any) => {
  if (!deviceList || deviceList?.length === 0) {
    return null;
  }
  return (
    <DownloadableDeviceList
      deviceList={deviceList}
      label={`Number of Failed Devices: ${deviceList.length}`}
      csvSheetName="Failed Devices"
      columnSchema={[
        { id: "device_id", label: "Device Id" },
        { id: "error", label: "Reason" },
      ]}
    />
  );
};

const SuccessDeviceList = ({ deviceList, operationType }: any) => {
  if (!deviceList || deviceList?.length === 0) {
    return null;
  }
  return (
    <DownloadableDeviceList
      deviceList={deviceList}
      label={`Number of Successful Devices: ${deviceList.length}`}
      csvSheetName={`Successfully ${
        operationType === "DEVICE_REPLACE" ? "Replaced" : "Updated"
      } Devices`}
      columnSchema={[{ id: "device_id", label: "Device ID" }]}
    />
  );
};

const statusRenderer: any = {
  [BULK_OPERATION_STATUS.NO_RECORD]: ({ message }: any) => {
    return <Typography>{message}</Typography>;
  },
  [BULK_OPERATION_STATUS.COMPLETED]: ({
    application_name,
    batch_size,
    created_on,
    failed_records,
  }: any) => {
    return (
      <Grid>
        <Record label="Batch Size" value={batch_size} />
        <Record label="Application" value={application_name} />
        <Record label="Created On" value={created_on} />
        <Record label="Status" value="Completed" />

        <FailedDeviceList deviceList={failed_records} />
      </Grid>
    );
  },
  [BULK_OPERATION_STATUS.IN_PROGRESS]: ({
    batch_size,
    created_on,
    application_name,
    operation_type,
  }: any) => {
    return (
      <Grid>
        <Record label="Batch Size" value={batch_size} />
        <Record label="Application" value={application_name} />
        <Record label="Created On" value={created_on} />
        <Typography style={{ margin: "35px 0" }}>
          Bulk {operation_type === "DEVICE_REPLACE" ? "Replace" : "Update"} is
          in progress...
        </Typography>
      </Grid>
    );
  },
  [BULK_OPERATION_STATUS.FAILED]: ({
    application_name,
    batch_size,
    created_on,
    failed_records,
    success_records,
    operation_type,
  }: any) => {
    return (
      <Grid>
        <Record label="Batch Size" value={batch_size} />
        <Record label="Application" value={application_name} />
        <Record label="Created On" value={created_on} />
        <Record label="Status" value="Failed" />
        <FailedDeviceList deviceList={failed_records} />
        <SuccessDeviceList
          deviceList={success_records}
          operationType={operation_type}
        />
      </Grid>
    );
  },
};

//API can send failure reason in 'error' or 'message' field, so sanitizing the data
const sanitizeFailedRecord = (failedRecords: any) => {
  return (failedRecords || []).map((record: any) => {
    return {
      device_id: record.device_id,
      error: record.error || record.message || "Unknown Error",
    };
  });
};

const sanitizeBulkOperationStatus = (bulkOperationStatus: any) => {
  return {
    ...bulkOperationStatus,
    failed_records: sanitizeFailedRecord(bulkOperationStatus.failed_records),
  };
};

const BulkOperationStatus = ({ bulkOperationStatus }: any) => {
  const sanitizedBulkOperationStatus =
    sanitizeBulkOperationStatus(bulkOperationStatus);
  const { status, ...statusData } = sanitizedBulkOperationStatus;

  return (
    <Grid style={{ width: "35em", margin: "1em .2em" }}>
      {(
        statusRenderer[status] ||
        (() => "An error occurred while Getting Status")
      )(statusData)}
    </Grid>
  );
};

export default BulkOperationStatus;
