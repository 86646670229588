import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AddThing from "./AddThing";
import { Grid, Tab, Tabs } from "@material-ui/core";
import BulkUpload from "./BulkDeviceUpload";
import BreadcumbComponent from "../../../../utils/breadcurmb";
import BulkUpdate from "./BulkUpdate";
import BulkReplace from "./BulkReplace";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ backgroundColor: "#1D1D25", color: "#FFFFFF" }}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabButton: {
    opacity: 1,
    color: "#FFFFFF",
    textTransform: "none",
    minWidth: "auto",
    marginRight: "30px",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "0px",
    minHeight: "42px",
    "&$selected": {
      fontWeight: "bold",
    },
  },
  tabSelected: {
    fontWeight: "bold",
  },
  tabPanel: {
    backgroundColor: "#1D1D25",
  },
  tabWrapper: {
    color: "#FFFFFF",
    padding: "0 24px",
  },
  gridRoot: {
    flexGrow: 1,
    padding: "0.5em 24px",
    backgroundColor: "#2B2B36",
  },
}));

export default function Config(props: any) {
  const classes = useStyles();
  const history = useHistory();

  const returnHome = () => history.push("/");

  const [value, setValue] =
    React.useState(0); /* 2 -> Tab index - default open */

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="config-wrapper outer-wrapper">
      <div className={classes.root}>
        <AppBar position="static" classes={{ root: classes.tabWrapper }}>
          <div
            className={classes.gridRoot}
            style={{
              marginRight: "1.5em",
              marginBottom: "1.5em",
              width: "100%",
            }}
          >
            <Grid
              container
              direction="row"
              xs={12}
              sm={6}
              style={{ marginTop: "1em", marginBottom: "1em" }}
              alignItems="center"
            >
              <BreadcumbComponent route="addDevice" func={props.returnHome} />
            </Grid>
          </div>

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tab"
            TabIndicatorProps={{ style: { background: "#FBCE07" } }}
            classes={{ root: classes.tabButton }}
          >
            <Tab
              label="Add Thing"
              {...a11yProps(0)}
              classes={{
                root: classes.tabButton,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Bulk Upload"
              {...a11yProps(1)}
              classes={{
                root: classes.tabButton,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Bulk Replace"
              {...a11yProps(2)}
              classes={{
                root: classes.tabButton,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Bulk Update"
              {...a11yProps(3)}
              classes={{
                root: classes.tabButton,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <AddThing returnHome={returnHome} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BulkUpload returnHome={returnHome} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BulkReplace returnHome={returnHome} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <BulkUpdate returnHome={returnHome} />
        </TabPanel>
      </div>
    </div>
  );
}
